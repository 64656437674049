import useTranslation from '../../useTranslation';
import { SharedComponent } from '../../../enums';

export function useMultiAuthText() {
  const { formatMessage, loading: textLoading } = useTranslation(
    SharedComponent.MULTI_AUTH_LOGIN_FORM
  );

  return {
    formatMessage,
    textLoading
  };
}
